import './App.css';

function App() {
  return (
    <div className="App">
      <div style={{margin: '80px auto',width: 'fitContent', fontSize: '28px'}}>
        lastisee's website
      </div>
    </div>
  );
}

export default App;
