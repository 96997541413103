import styles from './HomeM.less'

const HomeM = () =>{
    return (
        <div className={styles.homeM}>
            lastisee mobile-app
        </div>
    )
}

export default HomeM